import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import classes from "./addUserStyle.module.css";
import AuthContext from '../../../../context/Auth/AuthContext';
import { showErrorToast } from "../../../../Toster";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import TopNavBar from "../../layouts/TopNavBar";
var formData = new FormData();

const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
	name: Yup.string()
		.max(120, "Name must not exceed 120 characters")
		.required("Name is required"),
	email: Yup.string()
		.email("Invalid email address")
		.required("Email is required"),
	mobile: Yup.string()
		.required("Mobile is required")
		.matches(phoneRegExp, "Mobile number is not valid")
		.min(10, "Mobile number must be of 10 digits")
		.max(10, "Mobile number must be of 10 digits"),
	// role_id: Yup.string().required('Role is required'),
	// password: Yup.string().max(25, 'Password must not exceed 25 characters'),
	// address: Yup.string()
	// 	.max(200, 'Address must not exceed 200 characters')
	// 	.required('Address is required'),
};

const EditUser = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const { setLoaderCheck } = useContext(LoaderContext);
	const { authPermissions } = useContext(AuthContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);
	const [getData, setGetData] = useState({});
	const [initialValues, setInitialValues] = useState({
		name: "",
		email: "",
		mobile: "",
		// role_id: '',
		// password: '',
		// address: '',
		// city: ' ',
		// state: ' ',
		// zip: ' ',
	});

	useEffect(() => {
		setLoaderCheck(true);
		axiosPrivate
			.get("roles")
			.then((user_roles) => {
				setLoaderCheck(false);
				setRoles(user_roles?.data?.data);
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	useEffect(() => {
		setLoaderCheck(true);
		axiosPrivate
			.get(`users/${id}`)
			.then((response) => {
				setLoaderCheck(false);
				if (response.data.code === 200) {
					setGetData({
						...response.data.data, password: "",
						profile: response?.data?.data?.profile != null ? JSON.parse(response?.data?.data?.profile)?.profile : ''
					});
				}
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (error.response) {
					//response status is an error code
					console.log(error?.response?.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error?.request);
				} else {
					//an error occurred when setting up the request
					console.log(error?.message);
				}
			});
	}, [roles]);

	useEffect(() => {
		if (getData != "") {
			formik.setValues({ ...getData });
		}
	}, [getData]);

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			formData.append("name", values.name);
			formData.append("email", values.email);
			formData.append("mobile", values.mobile);
			formData.append("role_id", values.role_id);
			formData.append("profile", values.profile);
			setLoaderCheck(true);
			axiosPrivate
				.put(`users/${id}`, formData)
				.then((response) => {
					setLoaderCheck(false);
					setAlertMessage({ message: "User updated successfully" });
					navigate("/admin/users");
				})
				.catch((error) => {
					setLoaderCheck(false);
					if (error.response) {
						if (error.response.status !== 200) {
							for (let i = 0; i < error.response?.data?.errors?.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	return (
		<>
		<TopNavBar links={{ list: "/admin/users", add: "/admin/users/add" }} />
		<div className="section-body">
			<div className="container-fluid">
				<div className="tab-content">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<strong>Edit User</strong>
								</div>
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-6 col-md-6 col-sm-12">
											<div className="form-group">
												<input
													id="name"
													name="name"
													type="text"
													className="form-control"
													placeholder="Name *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.name}
												/>
												{formik.touched.name && formik.errors.name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.name}
													</div>
												) : null}
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<input
													id="email"
													name="email"
													type="text"
													className="form-control"
													placeholder="Email ID *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.email}
												/>
												{formik.touched.email && formik.errors.email ? (
													<div className={classes.invalidDataError}>
														{formik.errors.email}
													</div>
												) : null}
											</div>
										</div>
										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<input
													id="mobile"
													name="mobile"
													type="number"
													className="form-control"
													placeholder="Mobile No"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.mobile}
												/>
												{formik.touched.mobile && formik.errors.mobile ? (
													<div className={classes.invalidDataError}>
														{formik.errors.mobile}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-md-6 col-sm-12">
											<div className="form-group">
												<select
													className="form-control show-tick"
													id="role_id"
													name="role_id"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.role_id}
												>
													<option>Select Role</option>
													{roles.map((role, key) => (
														<option key={key + role.name} value={role.id}>
															{role.name}
														</option>
													))}
												</select>
												{formik.touched.role_id && formik.errors.role_id ? (
													<div className={classes.invalidDataError}>
														{formik.errors.role_id}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-lg-6 col-md-6 col-sm-12">
											<div className="form-group">
												<input
													id="profile"
													name="profile"
													type="file"
													accept="image/*"
													className="form-control"
													placeholder="Image *"
													onChange={(e) =>
														formik.setFieldValue(
															"profile",
															e.currentTarget.files[0]
														)
													}
													onBlur={formik.handleBlur}
												/>


												{typeof formik.values.profile === "string" &&
													formik.values.profile !== "" ? (
													<img
														className=""
														src={
															process.env.REACT_APP_COMMON_IMAGE_URL +
															"users/" +
															formik.values.profile
														}
														alt="About profile"
														loading="lazy"
														style={{
															width: "100px",
															height: "71px",
															marginTop: "10px",
															objectFit: "cover",
														}}
													/>
												) : (
													""
												)}
												{formik.touched.profile && formik.errors.profile ? (
													<div className={classes.invalidDataError}>
														{formik.errors.profile}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-12 text-right">
											<button
												type="button"
												id="button_1"
												className="btn btn-secondary mx-1"
												data-dismiss="modal"
												onClick={() => navigate("/admin/users")}
											>
												CLOSE
											</button>
											{authPermissions.includes('User-update') && (
												<button
													type="submit"
													id="button_2"
													className="btn btn-primary float-right"
												>
													Update
												</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		</>
	);
};

export default EditUser;
