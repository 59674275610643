import React from 'react';
import './imageGallery.css'
import { Galleria } from 'primereact/galleria';
import { ImagesearchRoller } from '@mui/icons-material';
        

const ImageGallery = ({gallery}) => {
  // const images = [
  //   'https://certy.px-lab.com/doctor/wp-content/uploads/sites/8/2017/06/dentist-chair-380x253.jpeg',
  //   'https://certy.px-lab.com/doctor/wp-content/uploads/sites/8/2017/06/chair-380x253.jpeg',
  //   'https://certy.px-lab.com/doctor/wp-content/uploads/sites/8/2017/06/clinic-380x253.jpg',
  //   'https://media.istockphoto.com/id/1487835903/photo/pov-of-a-patient-looking-up-while-surgeons-and-or-nurse-operate.webp?s=170667a&w=0&k=20&c=Ve-8BflrR49wfZv50awlSranlU8AKfOJ1l04Fz_2EyY=',
  //   'https://certy.px-lab.com/doctor/wp-content/uploads/sites/8/2017/06/dentist-380x253.jpg',
  //   'https://certy.px-lab.com/doctor/wp-content/uploads/sites/8/2017/06/dentist-room-380x253.jpg',
    
    
  // ];

  return (
    <div className="image-gallery">
       <>
      {gallery?.map((images, index) => (
       
        <div  key={index}>
          <img src={images.images} alt={`image ${index + 1}`} />
        </div>
        
      ))}
      </>
      {console.log(gallery)}
      {/* <Galleria value={images} style={{ maxWidth: '640px' }} showThumbnails={false} showIndicators item={images} /> */}
    </div>
    
  );
};

export default ImageGallery;
