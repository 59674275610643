import { useContext, useEffect } from "react";
import LoaderContext from "./LoaderContext";

const Loader = () => {

    const { loaderCheck } = useContext(LoaderContext);

    return (
        <>
            {loaderCheck ?
                <div className="spinner_class">
                    <div class="spinner-border text-success" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                : ''}
        </>
    );
}

export default Loader;