import React, { useContext, useEffect, useState } from "react";
import TopNavBar from "../../backend/layouts/TopNavBar";
import DataTable2 from "../../DataTable2";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { Link } from "react-router-dom";
import { showErrorToast } from "../../../Toster";
import AuthContext from "../../../context/Auth/AuthContext";
import LoaderContext from "../../../context/Loader/LoaderContext";

const HomeTemplate = () => {
	const [pageState, setPageState] = useState([]);
	const [refreshTable, setRefreshTable] = useState(false);
	const { authPermissions } = useContext(AuthContext);
	const { setLoaderCheck } = useContext(LoaderContext);

	const headers = [
		{ name: "Sr.No.", field: "sr.no.", classKey: "" },
		{ name: "NAME", field: "name", sortable: true, classKey: "" },
		{ name: "SLUG", field: "slug", sortable: true, classKey: "" },
		{ name: "ACTION", field: "action", classKey: "" },
	];

	const searchItems = ["name"];

	const getHomePageData = async () => {
		const options = {
			headers: { "content-type": "application/json" },
		};
		await axiosPrivate
			.get("pages", options)
			.then((pages) => {
				setLoaderCheck(false);
				let filteredData = pages.data.data.filter(
					(res) => res.template_type === "home"
				);
				const pageData = filteredData.map((value, index) => {
					let buttons = [];

					
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/home-template/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<i className="fa fa-edit"></i>
							</Link>
						);
					
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => pageDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<i className="fa fa-trash-o text-danger"></i>
							</button>
						);
					value["action"] = buttons.length > 0 ? buttons : "-";
					value["sr.no."] = index + 1;
					console.log(value)
					return value;
				});

				
					setPageState(pageData);
				
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (typeof error.response.data.errors == "array") {
					showErrorToast(error.response.data.errors[0]?.msg);
				} else {
					showErrorToast(error.response.data.errors?.msg);
				}
			});
	};

	const pageDeleteHandler = async (id) => {
		setLoaderCheck(true);
		await axiosPrivate
			.delete(`pages/${id}`)
			.then((response) => {
				setLoaderCheck(false);
				if (response.data.code == 200) {
					setRefreshTable(true);
				}
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
		await getHomePageData();
	};

	useEffect(() => {
		getHomePageData();
	}, [refreshTable, authPermissions]);

	const addLink = authPermissions.includes('Home Template-create') === true ? "/admin/home-template/add" : '';

	return (
		<div>
			<TopNavBar
				links={{
					list: "/admin/home-template",
					add: "/admin/home-template/add",
				}}
			/>
			<div className="section-body">
				<div className="container-fluid">
					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="category-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">PAGES LIST</h3>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={pageState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeTemplate;
