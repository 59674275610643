import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

function MonthlyRevenueChart(props) {
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            height: 350,
        },
        xaxis: {
            categories: [],
        },
    });

    useEffect(() => {
        const filteredData = props.data.filter(entry => entry.payment_status === 'pending');

        const uniqueMonths = [...new Set(filteredData.map(entry => new Date(entry.order_date).getMonth()))];
        
        // Create an array of month abbreviations in the correct order
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const monthlyRevenue = Array(months.length).fill(0);

        filteredData.forEach(entry => {
            const monthIndex = new Date(entry.order_date).getMonth();
            const revenue = parseFloat(entry.total_amount);
            monthlyRevenue[monthIndex] += revenue; // Use monthIndex directly
        });

        setSeries([{
            name: 'Monthly Revenue',
            data: monthlyRevenue,
        }]);

        setOptions({
            ...options,
            xaxis: {
                categories: months,
            },
        });
    }, [props.data]);

    return (
        <div>
            <div id="chart">
                <ReactApexChart options={options} series={series} type="bar" height={350} />
            </div>
        </div>
    );
}

export default MonthlyRevenueChart;
