import React, { useState } from "react";
import "./DoctorInformation.css";
import SkillScale from "./Dotbar";
import Circular from "./Circular";
import BorderLinearProgress from "./BorderLinearProgress";
import { Calendar } from "primereact/calendar";
import {
  FaBehance,
  FaDribbble,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaMusic,
  FaLinkedin,
  FaInstagram,
  FaHorse,
  FaSwimmer,
  FaTooth,
  FaBicycle,
  FaBus,
  FaDog,
} from "react-icons/fa";
import Doctor_image from "./Doctor_image";
import Timeline from "./Timeline";
import Timeline2 from "./Timeline2";
import Education from "./Education";
import ImageGallery from "./imageGallery";
import Faq from "./Faq";
import Refrences from "./Refrences";
import img1 from "../../../assets/img1.jpg";
import Popup from "./Popup";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import ReviewForm from "./Reviews";

const DoctorInformation = ({ initialData }) => {
  // Define state variables using the useState hook
  const [date1, setDate] = useState(null); // State for date
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup visibility
  const [visible, setVisible] = useState(false); // State to manage dialog visibility
  // Function to open the popup
  const openPopup = () => {
    setIsPopupOpen(true);
  };
  // Function to close the popup
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  // Function to handle form submission
  const handleSubmit = (formData) => {
    console.log("Form data:", formData);
    closePopup();
  };
  console.log(initialData);
  const handleDownload = () => {
    
    const fileUrl = initialData?.AboutUs?.bannerDocument
    
    // Create an anchor element to trigger the download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = 'document.docx'; // Set the desired file name here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Render the component's UI
  return (
    <div className="m-0">
      <div className="grandParent-div">
        <div className="parent-div">
          {/* Section 1: Doctor Information */}
          <div id="section1" className="section">
            <div className="child-div">
              {/* Doctor image and bio */}
              <div className="image">
                <div className="doc-img">
                  <Doctor_image docimg={initialData?.AboutUs?.DoctorImage} docimg2={initialData?.AboutUs?.DoctorImage2}  />
                </div>
                <br />
                <h2 style={{ textAlign: "center" }}>
                  {initialData?.AboutUs?.mainHeading}
                </h2>
                <br />
                <p
                  className="text1"
                  style={{ textAlign: "center", color: "black" }}
                >
                  <div className="skills">
                    <div className="skill1">
                      {initialData.AboutUs?.subHeading1}
                    </div>
                    <div className="skill2">
                      {initialData.AboutUs?.subHeading2}
                    </div>
                    <div className="skill3">
                      {initialData.AboutUs?.subHeading3}
                    </div>
                    <div className="skill4">
                      {initialData.AboutUs?.subHeading4}
                    </div>
                  </div>
                </p>
                {/* Social media links */}
                <div className="d-flex justify-content-center ">
                  <a
                    href="https://www.linkedin.com/in/amit-arora-2b009a76"
                    className="mx-3"
                  >
                    <FaLinkedin style={{ color: "#0077B5" }} size={20} />
                  </a>
                  <span style={{ color: "black" }}>Linkedin</span>
                  <a
                    href="https://instagram.com/amitarora.neuro?igshid=OGQ5ZDc2ODk2ZA=="
                    className="mx-3"
                  >
                    <FaInstagram style={{ color: "#0077B5" }} size={20} />
                  </a>
                  <span style={{ color: "black" }}>Instagram</span>
                </div>
                <br />
                <div className="text-box1 clear-mgr">
                  {/* Doctor's bio */}
                  <p
                    style={{
                      justifyContent: "center",
                      color: "black",
                      textAlign: "center",
                      padding: "0% 3% 0% 3%",
                    }}
                  >
                    {initialData.AboutUs?.card[0].content}
                  </p>
                  <br />
                  {/* Download CV button */}
                  {/* <div className="text-center mb-3">
                    <button onClick={handleDownload} className="btn-dnld" type="button">
                      Download CV
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Section 2: Personal Info */}

          <div id="section2" className="section">
            <div className="child2">
              <p
                style={{
                  justifyContent: "center",
                  color: "black",
                  textAlign: "left",
                  padding: "3% 3% 0% 3%",
                }}
              >
                {initialData.AboutUs?.card[0].content2}
              </p>

              <div className="line1"></div>

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <p>PERSONAL INFORMATION</p>
                  <table className="table table-borderless">
                    <tbody>
                      {initialData?.personalinfo?.card.map((data) => (
                        <tr>
                          <th scope="row">
                            <span
                              style={{ fontWeight: "bold", fontSize: "0.9em" }}
                            >
                              {data?.heading}
                            </span>
                          </th>
                          <td id="info">{data?.content}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <p>LANGUAGE</p>
                  {initialData?.language?.card?.map((data) => (
                    <>
                      <label className="style-list">{data.heading}</label>
                      <div className="d-flex">
                        <SkillScale initialValue={data.content} />

                        <span class="text-muted" style={{ marginTop: "-4%" }}>
                          {data.content >= 9 ? "native" : data.content >= 6 ? "fluent" : "basic"}
                        </span>
                      </div>
                    </>
                  ))}
                </div>

                <div className="line1"></div>

                <div className="col-lg-6 col-md-12 col-sm-12">
                  <p>PROFESSIONAL SKILLS</p>
                  {initialData.professional?.card.map((data) => (
                    <>
                      <label style={{ fontWeight: "bold" }}>
                        {data.heading}
                      </label>
                      <BorderLinearProgress
                        variant="determinate"
                        value={data.content}
                        className="mb-3"
                      />
                    </>
                  ))}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <p>PROFESSIONAL SKILLS</p>
                  {initialData?.professional2?.card.map((data) => (
                    <>
                      <label style={{ fontWeight: "bold" }}>
                        {data.heading}
                      </label>
                      <BorderLinearProgress
                        variant="determinate"
                        value={data.content}
                        className="mb-3"
                      />
                    </>
                  ))}
                </div>

                <div className="line1"></div>

                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <p>Area of expertise:</p>
                  <ul style={{ padding: "0" }}>
                    <li className="style-list">
                      <i class="fa fa-check fa-fw" style={{ marginRight: "5%" }} aria-hidden="true"></i>
                       <span style={{fontSize:"0.7em"}}>Management of Epilepsy including refractory seizures</span>
                      
                    </li>
                    <li className="style-list">
                      <i class="fa fa-check fa-fw" style={{ marginRight: "5%" }} aria-hidden="true"></i>
                      <span style={{fontSize:"0.7em"}}>Tingling Paraesthesia, Numbness and neuropathies</span>
                    </li>
                    <li className="style-list">
                      <i class="fa fa-check fa-fw" style={{ marginRight: "5%" }} aria-hidden="true"></i>
                      <span style={{fontSize:"0.7em"}}>Dizziness and Vertigo, Imbalance</span>
                    </li>
                    <li className="style-list">
                      <i class="fa fa-check fa-fw" style={{ marginRight: "5%" }} aria-hidden="true"></i>
                      <span style={{fontSize:"0.7em"}}>Movement disorders including Tremors, Parkinson, dystonia</span>
                    </li>
                    <li className="style-list">
                      <i class="fa fa-check fa-fw" style={{ marginRight: "5%" }} aria-hidden="true"></i>
                      <span style={{fontSize:"0.7em"}}>Headache management including Migraine and Cephalalgias</span>
                    </li>
                  </ul>
                </div> */}

                {/* <div className="col-lg-6 col-md-6 col-sm-12">
                  <p>Qualifications:</p>
                  <ul style={{ padding: "0" }}>
                    <li className="mb-3 style-list">
                    <i class="fa fa-check fa-fw" style={{ marginRight: "5%" }} aria-hidden="true"></i>
                      <span style={{fontSize:"0.7em"}}>Consultant Neurologist, MBBS MD(Gen Medicine) DM Neurology</span>
                    </li>
                    <li className="mb-3 style-list">
                    <i class="fa fa-check fa-fw" style={{ marginRight: "5%" }} aria-hidden="true"></i>
                      <span style={{fontSize:"0.7em"}}>SCE NEUROLOGY- (Royal College of Physicians, UK)</span>
                    </li>
                    <li className="mb-3 style-list">
                    <i class="fa fa-check fa-fw" style={{ marginRight: "5%" }} aria-hidden="true"></i>
                      <span style={{fontSize:"0.7em"}}>FELLOW OF EUROPEAN BOARD NEUROLOGY ( FEBN)</span>
                    </li>
                    <li className="mb-3 style-list">
                    <i class="fa fa-check fa-fw" style={{ marginRight: "5%" }} aria-hidden="true"></i>
                      <span style={{fontSize:"0.7em"}}>Fellowship in Epilepsy ( AIIMS, New Delhi)</span>
                    </li>
                  </ul>
                </div>
                <div className="line1"></div> */}
              </div>
            </div>
            {/* Section 3: Work Experience and Education */}
            <div id="section3" className="section">
              {/* <div>
                <h2 style={{ color: "black" }}>WORK EXPERIENCE</h2>
                <Timeline2 />
              </div> */}
              <div>
                <h2 style={{ color: "black" }}>PUBLICATION</h2>
                {/* <Timeline2 timelineData={initialData?.publication?.card} /> */}
                <p>
                During my neurology residency, my passion for unraveling neurological mysteries and commitment to ongoing learning led to numerous national and international publications that have significantly contributed to the field. These publications encompassed a wide range of topics, from rare variants of Alzheimer's disease and unusual presentations of botulism to the importance of treatment protocols for status epilepticus in neurosurgical settings and the predictive value of imaging techniques in epilepsy surgery outcomes. Additionally, my research explored the impact of intraoperative MRI on epilepsy surgery, the connection between expressed emotions and stigma in individuals with epilepsy in India, and the clinical relevance of valproic acid as an antiepileptic drug for surgeons. Even in resource-limited settings, I investigated the use of home video for epilepsy diagnosis. This body of work reflects my dedication to advancing neurology through rigorous research and a deep-seated passion for discovery.
                </p>
              </div>
              
              <div className="line1"></div>
              <div>
                <h2 style={{ color: "black" }}>WORK EXPERIENCE</h2>
                <Timeline workData={initialData?.workexp?.card} />
              </div>
              <div className="line1"></div>
              <div id="section4" className="section">
              <div>
                <h2 style={{ color: "black" }}>TEACHING EXPERIENCE</h2>
                <Timeline2 timelineData={initialData?.publication?.card} /> 
              </div>
              <div className="line1"></div>
              <div>
                <h2 style={{ color: "black" }}>EDUCATION AND INTERNATIONAL TRAININGS</h2>
                <Education educationData={initialData?.education?.card} />
              </div>
              <div className="line1"></div>
            </div>
          </div>
             <div>
              <h2 style={{ color: "black" }}>Certificate</h2>
              <br />
              <ImageGallery gallery={initialData?.gallery?.card} />
            </div>
            <br />
            <div className="line1"></div>
          {/* Section 4:References, Portfolio */}
          
            <div>
              <h2 style={{ color: "black" }}>Reviews</h2>
              <div className="container mt-5">
                <div className=" justify-content-center" style={{ boxShadow: "2px grey" }}>
                  <div>
                    <Refrences

                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="line1"></div>
            {/* <div className="row " style={{ alignItems: "center" }}>
              <div className="col-6 col-sm-4">
                <FaDog style={{ color: '#07AAF5' }} />
                <p style={{ fontWeight: 'bold' }}>Animals</p>
              </div>
              <div className="col-6 col-sm-4">
                <FaBus style={{ color: '#07AAF5' }} />
                <p style={{ fontWeight: 'bold' }}>Travel</p>
              </div>
              <div className="col-6 col-sm-4">
                <FaBicycle style={{ color: '#07AAF5' }} />
                <p style={{ fontWeight: 'bold' }}>Bicycle</p>
              </div>
              <div className="col-6 col-sm-4">
                <FaTooth style={{ color: '#07AAF5' }} />
                <p style={{ fontWeight: 'bold' }}>Teeth</p>
              </div>
              <div className="col-6 col-sm-4">
                <FaSwimmer style={{ color: '#07AAF5' }} />
                <p style={{ fontWeight: 'bold' }}>Swimming</p>
              </div>
              <div className="col-6 col-sm-4">
                <FaHorse style={{ color: '#07AAF5' }} />
                <p style={{ fontWeight: 'bold' }}>Horse</p>
              </div>
            </div>
            {/* <div className="line1"></div> */}
            {/* Section 5: Blog */}
            <div id="section5" className="section">
              <h2 style={{ color: "black" }}>Blog</h2>
              <br />

              <p
                style={{
                  justifyContent: "center",
                  color: "black",
                  textAlign: "center",
                  padding: "3% 3% 0% 3%",
                }}
              >
                Peruse my collection of informative medical blogs, offering
                expert insights across a range of health topics. Dive into my
                content to gain valuable knowledge and make informed choices
                about your health and well-being.
              </p>
              <div className="text-center">
                <button className="btn-dnld" type="button">
                  <Link style={{ color: "white" }} to="/Blogs">
                    Check Out My Blog
                  </Link>
                </button>
              </div>
              <br />

              <div
                className="card flex justify-content-center"
                style={{ border: "none" }}
              >
                <Calendar
                  value={date1}
                  onChange={(e) => setDate(e.value)}
                  inline
                  showWeek
                />
              </div>
              <br />
              <div style={{ fontWeight: "bold", textAlign: "center" }}>
                <p>Put your booking instructions here.</p>
                <p>Or you can leave it blank if there's nothing to display.</p>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  label="Schedule An Appointment"
                  icon="pi pi-external-link"
                  style={{ backgroundColor: "#07AAF5" }}
                  onClick={() => setVisible(true)}
                />
              </div>
              <Dialog
                header="Schedule An Appointment"
                visible={visible}
      
                onHide={() => setVisible(false)}
              >
                <Popup visible={visible} setVisible={setVisible}/>
              </Dialog>
            </div>
            <div className="line1"></div>
            {/* <div className="FAQ">
              <h2 style={{ color: "black" }}>FAQ</h2>
              <br />
              <br />
              <Faq />
            </div> */}
          </div>
          {/* Section 6: Map */}
          <div id="section6" className="section">
            <div className="Map" style={{ paddingTop: "3em" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14431.146454920785!2d55.3955289!3d25.2777627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5c2ee3119881%3A0x61180f85f50b8973!2sAster%20Hospital%2C%20Al%20Qusais!5e0!3m2!1sen!2sin!4v1694776743154!5m2!1sen!2sin"
                width="100%"
                height="500"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
             <div className="line1"></div>
             <h2 style={{ color: "black" }}>Share Your Reviews</h2>
             <br/>
             <ReviewForm/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorInformation;
