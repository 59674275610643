import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import TopNavBar from "../../layouts/TopNavBar";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import classes from "./addUserStyle.module.css";
import AuthContext from '../../../../context/Auth/AuthContext';
import { showErrorToast } from "../../../../Toster";
import LoaderContext from "../../../../context/Loader/LoaderContext";
var formData = new FormData();

const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
	name: Yup.string()
		.max(120, "Name must not exceed 120 characters")
		.required("Name is required"),
	email: Yup.string()
		.email("Invalid email address")
		.required("Email is required"),
	mobile: Yup.string()
		.required("Mobile is required")
		.matches(phoneRegExp, "Mobile number is not valid")
		.min(10, "Mobile number must be of 10 digits")
		.max(10, "Mobile number must be of 10 digits"),
	role_id: Yup.string().required("Role is required"),
	profile: Yup.mixed().required("profile Image is required"),
	password: Yup.string()
		.max(25, "Password must not exceed 25 characters")
		.required("Password is required"),
	// address: Yup.string()
	// 	.max(200, 'Address must not exceed 200 characters')
	// 	.required('Address is required'),
	// city: Yup.string()
	// 	.max(50, 'City must not exceed 200 characters')
	// 	.required('City is required'),
	// state: Yup.string()
	// 	.max(50, 'State must not exceed 200 characters')
	// 	.required('state is required'),
	// zip: Yup.string()
	// 	.required('Zip is required')
	// 	.matches(phoneRegExp, 'Zip is not valid')
	// 	.min(6, 'Zip must be of 6 digits')
	// 	.max(6, 'Zip must be of 6 digits'),
};

const AddUser = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const { setLoaderCheck } = useContext(LoaderContext);
	const { authPermissions } = useContext(AuthContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);

	useEffect(() => {
		setLoaderCheck(true);
		axiosPrivate
			.get("roles")
			.then((user_roles) => {
				setLoaderCheck(false);
				setRoles(user_roles?.data?.data);
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	const formik = useFormik({
		initialValues: {
			name: "",
			email: "",
			mobile: "",
			role_id: "",
			profile: "",
			password: "",
			// address: '',
			// city: '',
			// state: '',
			// zip: '',
			// landmark: ''
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			formData.append("name", values.name);
			formData.append("email", values.email);
			formData.append("mobile", values.mobile);
			formData.append("role_id", values.role_id);
			formData.append("profile", values.profile);
			formData.append("password", values.password);
			// formData.append("address", values.address);
			// formData.append("city", values.city);
			// formData.append("state", values.state);
			// formData.append("zip", values.zip);
			// formData.append("landmark", values.landmark);
			setLoaderCheck(true);
			axiosPrivate
				.post("users", formData)
				.then((response) => {
					setLoaderCheck(false);
					setAlertMessage({ message: "New user created successfully" });
					navigate("/admin/users");
					console.log(response);
				})
				.catch((error) => {
					setLoaderCheck(false);
					if (error.response) {
						if (error.response.status !== 201) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	return (
		<>
			<TopNavBar links={{ list: "/admin/users", add: "/admin/users/add" }} />
			<div className="section-body">
				<div className="container-fluid">
					<div className="tab-content">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong>Add User</strong>
									</div>
									<div className="card-body">
										<div className="row clearfix">
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="name"
														name="name"
														type="text"
														className="form-control"
														placeholder="Name *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.name}
													/>
													{formik.touched.name && formik.errors.name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="email"
														name="email"
														type="text"
														className="form-control"
														placeholder="Email *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.email}
													/>
													{formik.touched.email && formik.errors.email ? (
														<div className={classes.invalidDataError}>
															{formik.errors.email}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="mobile"
														name="mobile"
														type="number"
														className="form-control"
														placeholder="Mobile No."
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.mobile}
													/>
													{formik.touched.mobile && formik.errors.mobile ? (
														<div className={classes.invalidDataError}>
															{formik.errors.mobile}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<select
														className="form-control show-tick"
														id="role_id"
														name="role_id"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.role_id}
													>
														<option>Select Role</option>
														{roles.map((role, key) => (
															<option key={key + role.name} value={role.id}>
																{role.name}
															</option>
														))}
													</select>
													{formik.touched.role_id && formik.errors.role_id ? (
														<div className={classes.invalidDataError}>
															{formik.errors.role_id}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="password"
														name="password"
														type="password"
														className="form-control"
														placeholder="Password *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.password}
													/>
													{formik.touched.password && formik.errors.password ? (
														<div className={classes.invalidDataError}>
															{formik.errors.password}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="profile"
														name="profile"
														type="file"
														accept="image/*"
														className="form-control"
														placeholder="Image *"
														onChange={(e) =>
															formik.setFieldValue(
																"profile",
																e.currentTarget.files[0]
															)
														}
														onBlur={formik.handleBlur}
													/>
													{formik.touched.profile && formik.errors.profile ? (
														<div className={classes.invalidDataError}>
															{formik.errors.profile}
														</div>
													) : null}
												</div>
											</div>
											{/* <div className="col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="address"
														name="address"
														type="text"
														className="form-control"
														placeholder="Address *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.address}
													/>
													{formik.touched.address && formik.errors.address ? (
														<div className={classes.invalidDataError}>
															{formik.errors.address}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="landmark"
														name="landmark"
														type="text"
														className="form-control"
														placeholder="Landmark"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.landmark}
													/>
													{formik.touched.landmark && formik.errors.landmark ? (
														<div className={classes.invalidDataError}>
															{formik.errors.landmark}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="city"
														name="city"
														type="text"
														className="form-control"
														placeholder="City *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.city}
													/>
													{formik.touched.city && formik.errors.city ? (
														<div className={classes.invalidDataError}>
															{formik.errors.city}
														</div>
													) : null}
												</div>
											</div>

											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="state"
														name="state"
														type="text"
														className="form-control"
														placeholder="State *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.state}
													/>
													{formik.touched.state && formik.errors.state ? (
														<div className={classes.invalidDataError}>
															{formik.errors.state}
														</div>
													) : null}
												</div>
											</div>

											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<input
														id="zip"
														name="zip"
														type="number"
														className="form-control"
														placeholder="Zip *"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.zip}
													/>
													{formik.touched.zip && formik.errors.zip ? (
														<div className={classes.invalidDataError}>
															{formik.errors.zip}
														</div>
													) : null}
												</div>
											</div> */}

											<div className="col-12 text-right">
												<hr className="mt-4" />

												<button
													type="button"
													id="button_1"
													className="btn btn-secondary mx-1 "
													data-dismiss="modal"
													onClick={() => navigate("/admin/users")}
												>
													CLOSE
												</button>
												{authPermissions.includes('User-create') && (
													<button type="submit"	id="button_2" className="btn btn-primary">
														SUBMIT
													</button>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddUser;
