import Navbar from "./Navbarfront";
import DoctorInformation from "./DoctorInformation";
import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { RiMenu5Fill } from "react-icons/ri";
import Fixednav from "./Fixednav";
import { ScrollTop } from 'primereact/scrolltop';
import "./MainUi.css"
import HorizontalNavbar from "./HorizontalFixNav";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { useEffect } from "react";
import Topbar from "./Topbar";

const MainUi = () => {
  
  const [doctorData,setDoctorData]=useState([])
  useEffect(()=>{
    axiosPrivate.get("/pages/slug/new-page").then((res)=>{
    setDoctorData(JSON.parse(res.data?.data.page_json)) 
    }).catch((err)=>console.log(err))
  },[])
console.log(doctorData);
  return (
    
    <div className="m-0  main-container">
      <ScrollTop style={{backgroundColor:'#07AAF5'}}/>
      <div className="main-container d-none d-sm-inline">
        <Topbar/>
        <Navbar />
      </div>
      <div className="Horizontalnav text-center d-inline d-sm-none" style={{position:"fixed",top:'0',width:"100%",zIndex:'100'}}><HorizontalNavbar/></div>
      <div className="child-container">
        <div>
        <DoctorInformation initialData={doctorData}/>
        </div>
        <div className="d-none d-sm-inline">
        <Fixednav />
        </div>
      </div>
    </div>
  
  );
};

export default MainUi;
