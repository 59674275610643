import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import "./Fixnav.css"
import { Avatar } from 'primereact/avatar';
import img1 from "../../../assets/dr.amit_info/2.jpg"
import { FaAddressCard, FaBriefcase, FaComment, FaFeather, FaLocationArrow } from "react-icons/fa";

const HorizontalNavbar = () => {
  return (
    <Navbar id="horizontal-navbar" bg="white" style={{ width: '100%' }}>
      <Nav className="mx-3">
        <Nav.Link href="#section1"><Avatar image={img1} shape="circle" /></Nav.Link>
        <Nav.Link className='ml-3' href="#section2"><FaAddressCard size={25} /></Nav.Link>
        <Nav.Link className='ml-3' href="#section3"><FaBriefcase size={25} /></Nav.Link>
        <Nav.Link className='ml-3' href="#section4"><FaComment size={25} /></Nav.Link>
        <Nav.Link className='ml-3' href="#section5"><FaFeather size={25} /></Nav.Link>
        <Nav.Link className='ml-3' href="#section6"><FaLocationArrow size={25} /></Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default HorizontalNavbar;






