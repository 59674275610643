import React from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdEmail, MdLocationPin } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styles from './Topbar.module.css';

function Topbar() {
    return (
        <div className={styles.topbar}>
            {/* Contact */}
            <section className={styles.contactContainer}>
                <div className={styles.contactDetail}>
                    <p className={styles.tollFree}>Contact Me: <a href="tel:+971 50 205 9338"><span>+971 50 205 9338</span></a></p>
                    <Link to="/"> <BsThreeDotsVertical className={styles.setting} /></Link>
                    <a href="mailto:amitarora.neuro@gmail.com">
                        <div className={`${styles.mailContainer} ${styles.link}`}>
                            <MdEmail className={styles.mailIcon} />
                            <p className={styles.mail}>amitarora.neuro@gmail.com</p>
                        </div>
                    </a>
                </div>
            </section>

            {/* Account */}
            <section className={`${styles.accountContainer} ${styles.secondSection}`}>
                <div className={styles.social}>
                <a href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14431.146454920785!2d55.3955289!3d25.2777627!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5c2ee3119881%3A0x61180f85f50b8973!2sAster%20Hospital%2C%20Al%20Qusais!5e0!3m2!1sen!2sin!4v1694776743154!5m2!1sen!2sin">
                        <div className={`${styles.mailContainer} ${styles.link}`}>
                            <MdLocationPin  />
                            <p className={styles.mail}>Aster Hospital, Al Qusais Industrial area, Dubai</p>
                        </div>
                        </a>
                </div>
            </section>
        </div>
        
    )
}

export default Topbar