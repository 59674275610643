import React, { useState,useEffect } from 'react';
import axiosPrivate from "../../../hooks/axiosPrivate";
import { number } from 'yup';
import { showErrorToast, showSuccessToast } from '../../../Toster';


const Popup = ({setVisible,visible}) => {
  const [formData, setFormData] = useState({
    name: "",
    mobile:number,
    date: "",
    reason: "",
  });
  
  


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!formData.name || !formData.mobile) {
      alert('Please fill in all required fields.');
      return;
    }
    axiosPrivate.post("/appointment/create",formData).then((res)=>{
      if(res.data?.code==201){
       showSuccessToast("sucessfully submitted")
       setVisible(false)
      }
    }).catch((err)=>{
      showErrorToast(err?.response?.data?.message ||err?.response?.data?.errors[0]?.msg ||err?.response?.data?.errors?.msg)
    }) 
    
  };

  return (
    <div className={`pop ${visible ? 'active' : ''}`}>
      <div className="pop-content">
        <form>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="form-control" 
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Phone Number:</label>
            <input
              type="number"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              className="form-control" 
            />
          </div>
          <div className="form-group">
            <label htmlFor="age">date:</label>
            <input
              type="date"
              id="date"
              name="date"
              min={"2023-09-22"}
              value={formData.date}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label htmlFor="reason">Reason for Appointment:</label>
            <textarea
              id="reason"
              name="reason"
              value={formData.reason}
              onChange={handleInputChange}
              className="form-control" 
            />
          </div>
          <button type="button" onClick={e=>handleSubmit(e)} className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default Popup