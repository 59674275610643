import { useState, useEffect } from "react";
import CartCountContext from "./CartCountContext";
import axiosPrivate from './../../hooks/axiosPrivate';
import LoaderContext from "../Loader/LoaderContext";
import { useContext } from "react";

const CartCountState = (props) => {

    const [cartCount, setCartCount] = useState(0);
    const [cartCountCheck, setCartCountCheck] = useState(false);
    const { setLoaderCheck } = useContext(LoaderContext);

    useEffect(() => {
        let userCheck = JSON.parse(localStorage.getItem('userData'));
        if (userCheck) {
            setLoaderCheck(true);
            axiosPrivate.get("carts/get").then((res) => {
                setLoaderCheck(false);
                setCartCount(res?.data?.data?.length);
            }).catch((error) => {
                setLoaderCheck(false);
                console.log(error);
            });
        }
        else {
            const cartItems = JSON.parse(localStorage.getItem('cart_items'));
            console.log(cartItems);
            let count = cartItems?.length;
            setCartCount(count ?? 0);
        }
    }, [cartCountCheck])

    return (
        <>
            <CartCountContext.Provider value={{ cartCount, setCartCount, cartCountCheck, setCartCountCheck }}>
                {props?.children}
            </CartCountContext.Provider>
        </>
    );
}

export default CartCountState;