import { useState, useCallback, useEffect } from "react";
import AuthContext from "./AuthContext";
import axiosPrivate from "../../hooks/axiosPrivate";
import { useNavigate } from "react-router";

const AuthState = (props) => {

    const navigate = useNavigate();

    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem("userData") || "{}")
    );
    const [authPermissions, setAuthPermissions] = useState([]);

    useEffect(() => {
        if (user !== "" && user.userId !== undefined) {
            axiosPrivate
                .get(`user-has-permissions/user/${user.userId}`)
                .then((fetched_user_permissions) => {
                    if (fetched_user_permissions.data.data.length) {
                        let mergedPermissions = [];
                        for (
                            let i = 0;
                            i < fetched_user_permissions.data.data.length;
                            i++
                        ) {
                            mergedPermissions.push(
                                `${fetched_user_permissions.data.data[i].name}-${fetched_user_permissions.data.data[i].action}`
                            );
                        }
                        setAuthPermissions(mergedPermissions);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        //response status is an error code
                        console.log(error.response.status);
                    } else if (error.request) {
                        //response not received though the request was sent
                        console.log(error.request);
                    } else {
                        //an error occurred when setting up the request
                        console.log(error.message);
                    }
                });
        }
        // else {
        //   navigate("/user-panel/signin");
        // }
    }, [user]);

    function changeAuthPermissions() {
        if (user != "" && user.userId != undefined) {
            axiosPrivate
                .get(`user-has-permissions/user/${user.userId}`)
                .then((fetched_user_permissions) => {
                    if (fetched_user_permissions.data.data.length) {
                        let mergedPermissions = [];
                        for (
                            let i = 0;
                            i < fetched_user_permissions.data.data.length;
                            i++
                        ) {
                            mergedPermissions.push(
                                `${fetched_user_permissions.data.data[i].name}-${fetched_user_permissions.data.data[i].action}`
                            );
                        }
                        setAuthPermissions(mergedPermissions);
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        //response status is an error code
                        console.log(error.response.status);
                    } else if (error.request) {
                        //response not received though the request was sent
                        console.log(error.request);
                    } else {
                        //an error occurred when setting up the request
                        console.log(error.message);
                    }
                });
        }
    }

    function checkPermissionExists(permission) {
        if (authPermissions.includes(permission)) {
            return true;
        }
        return false;
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                authPermissions,
                setAuthPermissions,
                changeAuthPermissions,
                checkPermissionExists,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthState;
