import  React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import linearProgressClasses from '@mui/material/LinearProgress/linearProgressClasses';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const ProgressWithLabel = ({ value }) => (
  <Box display="flex" alignItems="center">
    <Box width="100%" mr={1}>
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
    <Box minWidth={35}>
     
    </Box>
  </Box>
);

export default ProgressWithLabel;
