import React, { useContext } from 'react';
import AuthContext from '../context/Auth/AuthContext';
import { axiosPublic } from './axiosPublic';
import axios from 'axios';

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  }
});

const userToken = JSON.parse(localStorage.getItem('userData') || '{}');

axiosApiInstance.interceptors.request.use(
  async config => {
    const userToken = JSON.parse(localStorage.getItem('userData') || '{}');
    config.headers = {
      'Authorization': `Bearer ${userToken.token}`,
      'Accept': 'application/json',
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    await RefreshAccessToken();
    const newToken = JSON.parse(localStorage.getItem('userData') || '{}');
    console.log(newToken, userToken)
    axiosApiInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken.token}`;
    originalRequest.headers.Authorization = `Bearer ${newToken.token}`;
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});

const RefreshAccessToken = async () => {
  const { user, setUser } = useContext(AuthContext);
  return new Promise(async (resolve, reject) => {
    const refreshToken = JSON.parse(localStorage.getItem('userData') || '{}');
    await axiosPublic.post('refresh-token', { refresh_token: refreshToken.refresh_token })
      .then((response) => {
        localStorage.setItem('userData', JSON.stringify(response.data));
        setUser(response.data);
        resolve(response.data);
      }, (error) => {
        reject(error.message);
      });
  });
};

export default axiosApiInstance;