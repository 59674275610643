import React, { useState } from "react";
import  Avatar  from "react-avatar";
import "./DoctorImage.css"

const Doctor_image = ({docimg,docimg2}) => {
  const [isHovered, setIsHovered] = useState(false);


  return (
    <div  >
      <div style={{textAlign:'center',marginTop: '10%'}}
        className="on-hoverA"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Avatar
          src={isHovered ? docimg2 : docimg}
          round={true}
          size="200"
          style={{
            display:'inline-block'
          }}
         
        />
      </div>
    </div>
  );
};

export default Doctor_image;
