import React from 'react';

const SkillScale = ({ initialValue }) => {
  const skillRadioStyle = {
    display: 'inline-block',
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    transition: 'background-color 0.3s',
    position: 'relative',
    border:'1px solid #07AAF5'
  };

  const filledStyle = {
    backgroundColor: '#07AAF5',
  };

  const formCheckInputStyle = {
    display: 'none',
  };

  return (
    <div className="d-flex">
      {Array.from({ length: 10 }, (_, index) => (
        <div key={index} className='mx-1'>
          <input
            type="radio"
            name="skillScale"
            value={index + 1}
            className="form-check-input"
            disabled
            checked={initialValue >= index + 1}
            style={formCheckInputStyle}
          />
          <label className="form-check-label skill-radio" style={{ ...skillRadioStyle, ...(initialValue >= index + 1 ? filledStyle : {}) }}>
            <div className="fill-color" />
          </label>
        </div>
      ))}
    </div>
  );
};

export default SkillScale;