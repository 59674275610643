import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from "../../../../Toster";
import AlertContext from "../../../../context/Alert/AlertContext";
import DataTable2 from '../../../DataTable2';
import axiosPrivate from '../../../../hooks/axiosPrivate';
import TopNavBar from '../../layouts/TopNavBar';
import AuthContext from '../../../../context/Auth/AuthContext';
import LoaderContext from '../../../../context/Loader/LoaderContext';

const Blog = () => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const { authPermissions } = useContext(AuthContext);
	const [blogState, setBlogState] = useState([]);
	const { setLoaderCheck } = useContext(LoaderContext);
	// header
	const headers = [
		{
			name: 'BLOG TITLE',
			field: 'title',
			sortable: true,
			classKey: '',
		},
		{ name: 'SLUG', field: 'slug', sortable: true, classKey: '' },


		{ name: 'ACTION', field: 'action', classKey: '' },
	];
	// search item
	const searchItems = ['title'];
	//  blogs data list
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		setLoaderCheck(true);
		axiosPrivate.get('blogs', options)
			.then((blogs) => {
				setLoaderCheck(false);
				const blogData = blogs?.data?.data?.map((value, key) => {
					let buttons = [];
					
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/blog/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<BiEdit style={{ color: 'green' }} size={18} />
							</Link>
						);
					
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => blogDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: 'red' }} size={17} />
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';

					return value; // console.log("uservalue",userData)
				});

				
					setBlogState(blogData);
				
			})
			.catch((error) => {
				setLoaderCheck(false);
				showErrorToast(error.message);
			})
	}, []);

	// alert
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	// delete function

	const blogDeleteHandler = async (id) => {
		setLoaderCheck(true);
		axiosPrivate
			.delete(`blogs/${id}`)
			.then(async (response) => {
				setLoaderCheck(false);
				if (response.data.code == 200) {
					setBlogState([]);
					setAlertMessage({ message: 'Blog  Deleted Successfully!' });
					await refreshTable();
				}
			})
			.catch((error) => {
				setLoaderCheck(false);
				if (typeof error.response.data.errors == 'array') {
					showErrorToast(error.response.data.errors[0].msg);
				} else {
					showErrorToast(error.response.data.errors.msg);
				}
			});
	};
	// refreshTable (delete data after get list)
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			setLoaderCheck(true);
			axiosPrivate.get('blogs', options)
				.then((blogs) => {
					setLoaderCheck(false);
					const blogData = blogs.data.data.map((value, key) => {
						let buttons = [];
						
							buttons.push(
								<button
									key="editButton##1"
									type="button"
									className="btn btn-icon"
									title="Edit"
								>
									<BiEdit style={{ color: 'green' }} size={18} />
								</button>
							);
						
							buttons.push(
								<button
									key="deleteButton##1"
									type="button"
									data-id={value.id}
									onClick={() => blogDeleteHandler(value.id)}
									className="btn btn-icon js-sweetalert"
									title="Delete"
								>
									<RiDeleteBinLine style={{ color: 'red' }} size={17} />
								</button>
							);
						value['action'] = buttons.length > 0 ? buttons : '-';
						return value;
					});

					
						setBlogState(blogData);
					

					resolve(true);
				})
				.catch((error) => {
					setLoaderCheck(false);
					console.log(error);
				})
		});
	};
	
	// const addLink = authPermissions.includes('Blog-create') === true ? '/admin/blog/add' : '';

	return (
		<>
			<TopNavBar links={{ list: '/admin/blog', add: '/admin/blog/add' }} />
			<div className="section-body">
				<div className="container-fluid">
					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={blogState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Blog;
