import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../../Toster";
import AlertContext from "../../../../context/Alert/AlertContext";
import axiosPrivate from "../../../../hooks/axiosPrivate";
import classes from "./Blog.module.css";
import LoaderContext from "../../../../context/Loader/LoaderContext";
import AuthContext from '../../../../context/Auth/AuthContext';
import TopNavBar from "../../layouts/TopNavBar";

const validationRules = {
  title: Yup.string().required('Title is required'),
  slug: Yup.string().required('Slug is required'),
  description: Yup.string().required('Description is required'),
  featured_image: Yup.string().required('Featured Image is required'),
  alt: Yup.string().required('Alt is required'),
  
};

const AddBlog = () => {
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  // const [blogTypeList, setBlogTypeList] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [ogImage, seOgImage] = useState("")
  const { setLoaderCheck } = useContext(LoaderContext);
  const { authPermissions } = useContext(AuthContext);

  // get all  Blog type
  // useEffect(() => {
  //   axiosPrivate
  //     .get("blog-type")
  //     .then((blogtype) => {
  //       setBlogTypeList(blogtype?.data?.data);
  //     })

  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  const handleOgImageChange = (e) => {
    // When a new file is selected, set the imagePreview state and update Formik field value
    const file = e.currentTarget.files[0];

    seOgImage(URL.createObjectURL(file))

    formik.setFieldValue("og_image", file);
  };

  const handleImageChange = (e) => {
    // When a new file is selected, set the imagePreview state and update Formik field value
    const file = e.currentTarget.files[0];
    setFeaturedImage(URL.createObjectURL(file));

    formik.setFieldValue("featured_image", file);

  };
  // formik(add)
  const formik = useFormik({
    initialValues: {
      title: "",
      slug: "",
      description: "",
      featured_image: "",
      alt: "",
    //   meta_title: "",
    //   keywords: "",
    //   meta_description: "",
    //   og_title: "",
    //   og_image: "",
    //   og_alt: "",
    //   og_description: "",
    //   blog_type: "",
    //   publish_date: "",
    //   tags: "",
    //   status: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      var formData = new FormData();

     

      formData.append("title", values.title);
      formData.append("slug", values.slug);
      formData.append("description", values.description);
      formData.append("featured_image", values.featured_image);
      formData.append("alt", values.alt);

      setLoaderCheck(true);
      axiosPrivate
        .post("blogs/create", formData)
        .then((response) => {
          setLoaderCheck(false);
          setAlertMessage({ message: "New  Blog Successfully" });
          navigate("/admin/blog");
        })
        .catch((error) => {
          setLoaderCheck(false);
          if (typeof error.response.data.errors == "array") {
            showErrorToast(error.response.data.errors[0].msg);
          } else {
            showErrorToast(error.response.data.errors.msg);
          }
        });
    },
    
  });
  console.log({FormData})

  return (
    <div>
      	<TopNavBar links={{ list: "/admin/blog", add: "/admin/blog/add" }} />
      <div className="section-body pt-1 ">
        <div className="container-fluid">
          <div className="tab-content mt-3 ">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row clearfix">
                      {/*   blog   Title */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Blog Title:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Blog Title "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*   blog Slug */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Slug:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="slug"
                            name="slug"
                            type="text"
                            className="form-control"
                            placeholder="Slug Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.slug}
                          />
                          {formik.touched.slug && formik.errors.slug ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.slug}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*  short Description */}
                      {/* <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>
                          Short Description:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <textarea
                            id="short_description"
                            name="short_description"
                            type="text"
                            className="form-control"
                            placeholder=" Short Description "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.short_description}
                          />
                          {formik.touched.short_description &&
                            formik.errors.short_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.short_description}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      {/* Detail  Description */}
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>
                          Description:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <textarea
                            id="description"
                            name="description"
                            type="text"
                            className="form-control"
                            placeholder="Description "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                          />
                          {formik.touched.description &&
                            formik.errors.description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Featured Image*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Featured Image:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="featured_image"
                            name="featured_image"
                            type="file"
                            className="form-control"
                            placeholder="Blog Title "
                            onChange={handleImageChange}
                            onBlur={formik.handleBlur}
                          />
                          {featuredImage && <img src={featuredImage} alt="Preview" style={{ maxWidth: '150px' }} />}
                          {formik.touched.featured_image &&
                            formik.errors.featured_image ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.featured_image}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Featured   images alt*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Featured Image Alt:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="alt"
                            name="alt"
                            type="text"
                            className="form-control"
                            placeholder="Featured Image Alt"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.alt}
                          />
                          {formik.touched.alt && formik.errors.alt ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.alt}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Meta Title heading*/}
                      {/* <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <p
                          className="my-3"
                          style={{ fontWeight: "700", fontSize: "20px" }}
                        >
                          Meta Section
                        </p>
                      </div> */}

                      {/*Meta Title*/}
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Meta Title:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="meta_title"
                            name="meta_title"
                            type="text"
                            className="form-control"
                            placeholder="Meta Title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_title}
                          />
                          {formik.touched.meta_title &&
                            formik.errors.meta_title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_title}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      {/*Keywords*/}
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Keywords:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="keywords"
                            name="keywords"
                            type="text"
                            className="form-control"
                            placeholder="Keywords"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.keywords}
                          />
                          {formik.touched.keywords && formik.errors.keywords ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.keywords}
                            </div>
                          ) : null}
                        </div>
                      </div>
{/*  */}
                      {/*  meta Description */}
                      {/* <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>
                          Meta Description:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <textarea
                            id="meta_description"
                            name="meta_description"
                            type="text"
                            className="form-control"
                            placeholder="Meta Description "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_description}
                          />
                          {formik.touched.meta_description &&
                            formik.errors.meta_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_description}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      {/* Og  Section heading*/}
                      {/* <div className="col-lg-12 col-md-12 col-sm-12">
                        <p
                          className="my-3"
                          style={{ fontWeight: "700", fontSize: "20px" }}
                        >
                          OG Section
                        </p>
                      </div> */}

                      {/* Og Title*/}
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Og Title:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="og_title"
                            name="og_title"
                            type="text"
                            className="form-control"
                            placeholder="Og Title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.og_title}
                          />
                          {formik.touched.og_title && formik.errors.og_title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_title}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      {/* Og  Image*/}
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Og Image:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="og_image"
                            name="og_image"
                            type="file"
                            className="form-control"
                            placeholder="Og  Image"
                            onBlur={formik.handleBlur}
                            onChange={handleOgImageChange}

                          />
                          {ogImage && <img src={ogImage} alt="Preview" style={{ maxWidth: '150px' }} />}
                          {formik.touched.og_image && formik.errors.og_image ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_image}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      {/* Og  Alt*/}
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Og Alt:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="og_alt"
                            name="og_alt"
                            type="text"
                            className="form-control"
                            placeholder="Og   Alt"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.og_alt}
                          />
                          {formik.touched.og_alt && formik.errors.og_alt ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_alt}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      {/*  Og Description */}
                      {/* <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>
                          Og Description:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <textarea
                            id="og_description"
                            name="og_description"
                            type="text"
                            className="form-control"
                            placeholder="Og Description "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.og_description}
                          />
                          {formik.touched.og_description &&
                            formik.errors.og_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_description}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      {/*  Blog  Category */}

                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Blog Category:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="role"
                            name="blog_type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.blog_type}
                          >
                            <option>Select Blog Type</option>
                            {blogTypeList.map((role, key) => (
                              <option key={key + role.id} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.blog_type &&
                            formik.errors.blog_type ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.blog_type}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      {/*  publish date*/}
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Publish Date:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="publish_date"
                            name="publish_date"
                            type="date"
                            className="form-control"
                            placeholder="Publish Data"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values.publish_date
                            }
                            min={new Date().toISOString().split("T")[0]}
                          />
                          {formik.touched.publish_date &&
                            formik.errors.publish_date ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.publish_date}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      {/*Tags*/}
                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Tags:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="tags"
                            name="tags"
                            type="text"
                            className="form-control"
                            placeholder="Tags"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.tags}
                          />
                          {formik.touched.tags && formik.errors.tags ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.tags}
                            </div>
                          ) : null}
                        </div>
                      </div> */}

                      {/*   status */}

                      {/* <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Status:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="status"
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                          >
                            <option>Select Status</option>
                            <option>Draft</option>
                            <option>Pending</option>
                            <option>Published</option>
                          </select>
                        </div>
                      </div>  */}

                      {/* button close and submitF */}
                      <div className="col-12 submit-btn  text-right">
                        <button
                          type="button"
                          id="button_1"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/blog")}
                        >
                          Close
                        </button>
                        
                          <button type="submit" id="button-2" className="btn btn-primary ml-2">
                            Submit
                          </button>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
